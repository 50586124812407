import React from 'react';

const Footer = () => {
    return (
        <div id="footer">
            <div className="container text-center">
                <p>Copyright © 2024 - Biometrik® Systems Pvt. Ltd</p>
            </div>
        </div>
    );
};

export default Footer;
