import React, { useEffect } from 'react'
import '../App.css';

const Contact2 = () => {
    useEffect(() => {
        // Create the iframe element
        const iframe = document.createElement('iframe');
        iframe.src =
            'https://forms.zohopublic.in/biometrik1/form/Contactusform/formperma/rhCetYmzBWP617bdwAkmW2KCr7aGT8ShW2wtmeAyrC0?zf_rszfm=1';
        iframe.style.border = 'none';
        iframe.style.height = '1400px';
        iframe.style.width = '90%';
        iframe.style.transition = 'all 0.5s ease';
        iframe.setAttribute('aria-label', 'Get In Touch');

        // Append the iframe to the div with id
        const divElement = document.getElementById(
            'zf_div_rhCetYmzBWP617bdwAkmW2KCr7aGT8ShW2wtmeAyrC0'
        );
        if (divElement) {
            divElement.appendChild(iframe);
        }
    }, []);

    return <div id="zf_div_rhCetYmzBWP617bdwAkmW2KCr7aGT8ShW2wtmeAyrC0"></div>;
};

export default Contact2;