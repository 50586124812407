export const Ekyc = (props) => {
    return (
        <div id="ekyc" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Centralized Identity & KYC</h2>
                    <p>
                        Centralized KYC (Know Your Customer) verification
                        streamlines the process of identity validation for
                        businesses by consolidating customer data into a secure,
                        centralized database. This approach simplifies
                        compliance with regulatory requirements, allowing
                        organizations to efficiently verify customer identities
                        through a standardized process. Utilizing advanced
                        technologies, such as document verification and
                        biometric authentication, centralized KYC enhances
                        security and reduces the risk of fraud. By ensuring that
                        customer information is accurately captured and easily
                        accessible, businesses can deliver a smoother onboarding
                        experience while maintaining rigorous standards for
                        identity verification. This centralized framework not
                        only improves operational efficiency but also
                        strengthens customer trust in the institution’s
                        commitment to security and regulatory compliance.
                    </p>
                </div>
            </div>
        </div>
    );
};
