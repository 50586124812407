
import React from 'react';


export const Team = (props) => {
    return (
        <div id="team" className="text-center team-container">
            <div className="container">
                <div className="col-md-12 mx-auto section-title">
                    <h2>Meet the Founder</h2>
                </div>

                <div className="row justify-content-center">
                    {props.data
                        ? props.data.map((d, i) => (
                            <div key={`${d.name}-${i}`} className="col-md-12  mb-4">
                                <div className="row align-items-center">
                                    {/* Image Column */}
                                    <div className="col-12 col-md-2 mb-3 mb-md-0">
                                        <img
                                            src={d.img}
                                            alt={d.name}
                                            className="img-fluid team-img"
                                            style={{ width: '100%', height: 'auto',padding:'20px' }}
                                        />
                                        <h4>{d.name}</h4>
                                        <p>{d.job}</p>
                                    </div>
                                    {/* Content Column */}

                                    <div className="col-12 col-md-10 text-left content-area" >

                                        <p>
                                            Ram is former Head of Product Innovation at Digi Yatra Foundation, a national platform managing Self Sovereign Identity technology & face recognition technology which has already enabled 10 million passengers get seamless entry to various touch points in ~30 airports across India.
                                        </p>
                                        <p>
                                            Ram is a visionary in the field of decentralized identity management systems and verification using face recognition, and an industry leader in artificial intelligence.
                                        </p>
                                        <p>
                                            Ram is an innovator, inventor & holder of a patent on face liveness detection technology.
                                        </p>
                                        <p>
                                            Ram was awarded and recognized for excellence in Artificial Intelligence by Ministry of Electronics & Information Technology (MeitY), Centre for Development of Advanced Computing (C-DAC), National Supercomputing Mission (NSM) and Department of Science & Technology (DST), Govt. of India.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                        : 'loading'}
                </div>
            </div>
        </div>
    );
};
