import React from 'react';
export const LivenessDetection = (props) => {
    return (
        <div id="Liveness" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Face liveness detection</h2>
                    <p>
                        Face liveness check during face verification and
                        recognition as a service is an essential innovation that
                        enhances the security of both centralized and
                        decentralized identity solutions. By ensuring that the
                        facial recognition process detects genuine users rather
                        than spoof attempts, such as photographs or videos,
                        liveness detection significantly reduces the risk of
                        fraud. Our patented liveness check technology employs
                        advanced techniques to verify the authenticity of a live
                        face, adding a robust layer of protection to identity
                        verification processes. In centralized systems, this
                        feature streamlines KYC procedures by automating secure
                        identity validation, while in decentralized frameworks,
                        it empowers users to authenticate themselves safely and
                        confidently. By integrating liveness checks into facial
                        recognition services, organizations can provide a
                        trustworthy and user-friendly experience, reinforcing
                        their commitment to security and regulatory compliance.
                    </p>
                </div>
            </div>
        </div>
    );
};
