import React from 'react';
import overlayGif from '../Gif/FACIAL2.gif'; // Overlay GIF
import './styles/HeaderContent.css';

const HeaderContent = ({ data }) => {
    return (
        <div className="content">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 left">
                        <div className="col-md-10 col-md-offset-1 header-content-overlay-text">
                            <h1>
                                {data ? data.title : 'Loading'}
                                <span></span>
                            </h1>
                            <p>{data ? data.paragraph : 'Loading'}</p>
                            <div
                                className="col-md-6 head-scan-responsive-mob"
                            >
                                <img
                                    className="overlay header-scan-image"
                                    src={overlayGif} // Overlay GIF
                                    alt="Overlay Animation"
                                />
                            </div>
                            <a
                                href="#features"
                                className="btn btn-custom btn-lg page-scroll"
                            >
                                Learn More
                            </a>
                        </div>
                    </div>
                    <div
                        className="col-md-6 right position-relative head-scan-responsive"
                    >
                        <img
                            className="overlay header-scan-image"
                            src={overlayGif} // Overlay GIF
                            alt="Overlay Animation"
                        />
                        {/* <img
                            className="earth"
                            height="500px"
                            src={facerec}
                            alt="Face Recognition"
                            ref={imageRef}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                            style={{ zIndex: 1 }}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderContent;