
import React from 'react';
import video1 from '../video/video1.mp4';
import video2 from '../video/video2.mp4';
import TestimonialImage from '../assets/Images/Ram-Kunchur-Founder-and-CEO-of-Biometrik-Systems-600x547.jpg';

export const Exclusives = (props) => {
    return (
        // <div id="portfolio" className="text-center">
        //     <div className="container">
        //         <div className="section-title">
        //             <h2>Testimonials</h2>
        //         </div>
        //         <div className="row">
        //             <div className="col-12 col-sm-6 mb-4 d-flex justify-content-center video-margin ">
        //                 <div> my testimonials</div>
        //                 {/* <video
        //                     src={video1}
        //                     type="video/mp4"
        //                     loop
        //                     className="mx-auto"
        //                     style={{ width: '100%', maxWidth: '500px' }}
        //                     controls
        //                     controlsList="nodownload noremoteplayback noplaybackspeed"
        //                     disablePictureInPicture
        //                 /> */}
        //             </div>
        //             {/* <div className="col-12 col-sm-6 d-flex justify-content-center video-margin ">
        //                 <video
        //                     src={video2}
        //                     type="video/mp4"
        //                     loop
        //                     className="mx-auto"
        //                     style={{ width: '100%', maxWidth: '500px' }}
        //                     controls
        //                     controlsList="nodownload noremoteplayback noplaybackspeed"
        //                     disablePictureInPicture
        //                 />
        //             </div> */}
        //         </div>
        //     </div>
        // </div>
        <div className='container'>
            <div className='testimonial-container'>
                <div className='col-md-12 mx-auto section-title'>
                    <h2>Testimonials</h2>
                </div>
                <div className='row align-items-center'>
                    <div className='testimonial-item col-md-12'>
                        <div className='testimonial-img-container col-12 col-md-2'>
                            <img src={TestimonialImage} alt='testimonial' />
                            <h4>Ram Kunchur</h4>
                            <p>Founder & CEO</p>
                        </div>
                        <div className='col-12 col-md-10 text-left content-area'><strong><b>Biometrik Champions SSI Tech for More Security in Biometrics<br></br></b></strong>

                            Biometrik Systems, founded by Ram Kunchur, former Head of Product Innovation at Digi Yatra Foundation, is a trailblazing tech company at the forefront of identity management and AI innovation. With 17 years of experience driving their vision, Biometrik offers a powerful suite of solutions, including decentralized and centralized identity systems, advanced facial recognition and verification, and a patented face-liveness detection technology that sets them apart.

                            Their Software-as-a-Service (SaaS) solutions are equally cutting-edge, delivering seamless face recognition, face liveness detection, and decentralized verifiable credentials—all with a sharp focus on self-sovereign identity (SSI). But Biometrik doesn’t stop at software. They’re also leaders in supplying and integrating high-tech face reader devices and access control systems, like eGates, barriers, and doors, providing frictionless identity verification across diverse environments.


                            <a href='https://theinterview.world/biometrik-champions-ssi-tech-for-more-security-in-biometrics/' className='read-more'>Read More...</a></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

