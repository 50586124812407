import React from 'react'
import video3 from '../video/video3.mp4';
import '../../src/components/styles/NewExclusive.css';

function NewExclusive() {
    return (
        <div id="FaceLiveness">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        {/* <video
                        id="#background-video"
                        src={video3} 
                        className="img-responsive"
                        alt="About Us"
                        style={{ paddingTop: '20px' }}
                        controls
                    ></video> */}
                        <iframe
                            src="https://www.youtube.com/embed/cAB2iYjxvrQ"
                            title="YouTube video"
                            className="mx-auto"
                            style={{ width: '100%', maxWidth: '500px', height: '350px' }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            controls
                            controlsList="nodownload noremoteplayback noplaybackspeed"
                            disablePictureInPicture
                        />
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="about-text">
                            <h2 className='pname'>Patented Face Liveness</h2>
                            <h3>
                                Patented Anti-Spoofing and Liveness Check
                                Process
                                <br />
                            </h3>
                            <p>
                                At Biometrik® Systems, we prioritize security
                                and user convenience with our innovative
                                patented anti-spoofing and liveness check
                                process. Our technology ensures that your
                                identity is securely verified, preventing fraud
                                and unauthorized access.
                            </p>
                            <h3>How It Works</h3>
                            <div className="list-style">
                                <ul className="list-unstyled">
                                    <li>
                                        <strong>Face Positioning</strong>
                                        The user is asked to align their face
                                        within a circular area on the camera
                                        screen. This helps the system detect and
                                        focus on the face correctly.
                                    </li>
                                    <li>
                                        <strong>Random Facial Gestures</strong>
                                        Once the face is positioned, the user is
                                        prompted to perform a series of random
                                        facial gestures, such as:
                                        <ul style={{ listStyleType: 'disc' }}>
                                            <li style={{ marginLeft: '30px' }}>
                                                Blinking eyes
                                            </li>
                                            <li style={{ marginLeft: '30px' }}>
                                                Opening the mouth
                                            </li>
                                            <li style={{ marginLeft: '30px' }}>
                                                Moving the head left or right
                                            </li>
                                            <li style={{ marginLeft: '30px' }}>
                                                Looking up or down
                                            </li>
                                            <li style={{ marginLeft: '30px' }}>
                                                Tilting the head left or right
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Timed Responses</strong>
                                        Each gesture must be completed within a
                                        specific time frame. If the user fails
                                        to perform an action within the allotted
                                        time or performs it incorrectly, the
                                        process restarts. This ensures that the
                                        actions are performed by a live person
                                        and not pre-recorded videos or images.
                                    </li>
                                    <li>
                                        <strong>Real-Time Validation</strong>
                                        The next gesture is revealed only after
                                        the current one is successfully
                                        completed. This random and sequential
                                        nature makes it extremely difficult for
                                        impostors to predict or replicate the
                                        actions.
                                    </li>
                                    <li>
                                        <strong>Liveness Confirmation</strong>
                                        Once all gestures are performed
                                        successfully within the given time and
                                        order, the system confirms the user's
                                        liveness. If any action fails, the
                                        verification process is denied, and the
                                        user must start over.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewExclusive;