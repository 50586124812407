import React from 'react';
import '../App.css';
import AWSLogo from '../assets/Images/amazone_partner.png';

const Partner = () => {
    return (
        <div className='container partner-container'>
            <div className='col-md-8 mx-auto section-title'>
                <h2>Partners</h2>
            </div>
            <div className='partner-scroll-container'>
                <div className='partner-image-container'>
                    <img src={AWSLogo} alt='AWS_Logo' style={{width:'100%',height:'auto',padding: '20px 0px 50px'}}  />
                </div>
            </div>
        </div>
    )
}

export default Partner