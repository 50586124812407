import React from 'react';

export const Testimonials = (props) => {
    return (
        <div id="testimonials">
            <div className="container">
                <div className="section-title text-center">
                    <h2>Identity verification use cases</h2>
                </div>
                <div className="row">
                    {props.data
                        ? props.data.map((d, i) => (
                              <div
                                  key={`${d.name}-${i}`}
                                  className="col-md-6 col-sm-6 col-xs-12"
                              >
                                  <div className="testimonial">
                                      <div className="testimonial-image">
                                          <img src={d.img} alt="" />
                                      </div>
                                      <div className="testimonial-content">
                                          <p>{d.text}</p>
                                          <div className="testimonial-meta"></div>
                                      </div>
                                  </div>
                              </div>
                          ))
                        : 'loading'}
                </div>
            </div>
        </div>
    );
};
