import React, { useEffect, useRef } from 'react';
import GIF1 from '../Gif/KYC.gif';
import GIF2 from '../Gif/LivenessDetection.gif';
import GIF3 from '../Gif/Recognition.gif';
import GIF4 from '../Gif/DecentralizedIdentity.gif';
import '../App.css';

export const Features = (props) => {
    const gifs = [GIF1, GIF2, GIF3, GIF4];
    const sectionRef = useRef(null);

    useEffect(() => {
        const section = sectionRef.current;

        const handleScroll = () => {
            const rect = section.getBoundingClientRect();
            if (rect.top < window.innerHeight && rect.bottom >= 0) {
                section.classList.add('visible');
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            id="features"
            className="text-center slide-in-left"
            ref={sectionRef}
        >
            <div className="container">
                <div className="col-md-10 col-md-offset-1 section-title">
                    <h2>Products</h2>
                </div>
                <div className="products-feature-grid">
                    {props.data
                        ? props.data.map((d, i) => (
                              <div
                                  key={`${d.title}-${i}`}
                                  className="product-feature-grid-item"
                              >
                                  <a href={d.href}>
                                      <img
                                          src={gifs[i % gifs.length]}
                                          alt={d.title}
                                          height={120}
                                          width={120}
                                      />
                                  </a>
                                  <h3>{d.title}</h3>
                                  <p>{d.text}</p>
                              </div>
                          ))
                        : 'Loading...'}
                </div>
            </div>
        </div>
    );
};
