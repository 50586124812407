import { useState } from 'react';
import emailjs from 'emailjs-com';
import React from 'react';

const initialState = {
    from_name: '',
    from_email: '',
    message: '',
};

export const Contact = (props) => {
    const [{ from_name, from_email, message }, setState] =
        useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const clearState = () => setState({ ...initialState });

    const handleSubmit = (e) => {
        e.preventDefault();

        // Sending email
        emailjs
            .sendForm(
                'service_vgrfppd', // Replace with your service ID
                'template_ij4xhpk', // Replace with your template ID
                e.target,
                'YG_uzq6jivc923xyi' // Replace with your user ID
            )
            .then(
                (result) => {
                    console.log('Email successfully sent:', result.text);
                    clearState(); // Clear the form after successful submission
                },
                (error) => {
                    console.log('Email sending failed:', error.text);
                }
            );
    };

    return (
        <div id="contact">
            <div className="container">
                {/* <div className="col-md-8">
                    <div className="row">
                        <div className="section-title">
                            <h2>Get In Touch</h2>
                            <p>
                                Please fill out the form below to send us an
                                email and we will get back to you as soon as
                                possible.
                            </p>
                        </div>
                        <form
                            name="sentMessage"
                            validate="true"
                            onSubmit={handleSubmit}
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="name"
                                            name="from_name"
                                            className="form-control"
                                            placeholder="Name"
                                            required
                                            onChange={handleChange}
                                            value={from_name}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            id="email"
                                            name="from_email"
                                            className="form-control"
                                            placeholder="Email"
                                            required
                                            onChange={handleChange}
                                            value={from_email}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <textarea
                                    name="message"
                                    id="message"
                                    className="form-control"
                                    rows="4"
                                    placeholder="Message"
                                    required
                                    onChange={handleChange}
                                    value={message}
                                ></textarea>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-custom btn-lg"
                            >
                                Send Message
                            </button>
                        </form>
                    </div>
                </div> */}
                {/* Contact Info */}
                {/* <div className="col-md-3 col-md-offset-1 contact-info">
                    {/* Address 
                    <div className="contact-item">
                        <h3>Contact Info</h3>
                        <p>
                            <span>
                                <i className="fa fa-map-marker"></i> Address
                            </span>
                            {props.data ? props.data.address : 'loading'}
                        </p>
                    </div>
                    {/* Phone 
                    <div className="contact-item">
                        <p>
                            <span>
                                <i className="fa fa-phone"></i> Phone
                            </span>{' '}
                            {props.data ? props.data.phone : 'loading'}
                        </p>
                    </div>
                    {/* Email 
                    <div className="contact-item">
                        <p>
                            <span>
                                <i className="fa fa-envelope-o"></i> Email
                            </span>{' '}
                            {props.data ? props.data.email : 'loading'}
                        </p>
                    </div>
                </div> */}

<div className="col-md-10 col-md-offset-1 contact-info">
                    {/* Address */}
                    <h3>Contact Info</h3>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-map-marker"></i> Address
                                </span>
                                {props.data ? props.data.address : 'loading'}
                            </p>
                        </div>
                        {/* Phone */}
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-phone"></i> Phone
                                </span>{' '}
                                {props.data ? props.data.phone : 'loading'}
                            </p>
                        </div>
                        {/* Email */}
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-envelope-o"></i> Email
                                </span>{' '}
                                {props.data ? props.data.email : 'loading'}
                            </p>
                        </div>
                    </div>
                </div>


                {/* Footer */}
                <div className="col-md-12">
                    <div className="row">
                        <div className="social">
                            <ul>
                                <li>
                                    <a
                                        href={
                                            // props.data
                                            //     ? props.data.facebook
                                            //     : 
                                                'https://www.instagram.com/biometrik.in'
                                        }
                                    >
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={
                                            props.data
                                                ? props.data.twitter
                                                : '/'
                                        }
                                    >
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={
                                             'https://www.linkedin.com/company/biometrik/'
                                        }
                                    >
                                        <i className="fa fa-linkedin"></i>
                                    </a>
                                </li>
                                {/* <li>
                                    <a
                                        href={
                                            props.data
                                                ? props.data.youtube
                                                : '/'
                                        }
                                    >
                                        <i className="fa fa-youtube"></i>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
