import React from 'react';
import video1 from '../video/video1.mp4';
import video2 from '../video/video2.mp4';
// import video3 from '../video/video3.mp4'; // Corrected video3 source
import video1Thumbnail from '../assets/Images/video1Thumbnail.jpeg';
import video2Thumbnail from '../assets/Images/video2Thumbnail.jpeg';
export const Gallery = (props) => {
    return (
        <div id="portfolio" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Videos</h2>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 mb-4 d-flex justify-content-center video-margin ">
                    <iframe
  src="https://www.youtube.com/embed/yxYyB-6X-9M"
  title="YouTube video"
  className="mx-auto"
  style={{ width: '100%', maxWidth: '500px', height: '350px' }}
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
  controls
  controlsList="nodownload noremoteplayback noplaybackspeed"
  disablePictureInPicture
/>
                    </div>
                    <div className="col-12 col-sm-6 d-flex justify-content-center video-margin ">
                        <iframe
                            src="https://www.youtube.com/embed/zWmL_V0kUbA"
                            title="YouTube video"
                            poster={video2Thumbnail} // You can't use this directly, YouTube doesn't support it
                            className="mx-auto"
                            style={{ width: '100%', maxWidth: '500px',height:'350px' }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            controls
                            controlsList="nodownload noremoteplayback noplaybackspeed"
                            disablePictureInPicture
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

