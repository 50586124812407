import React from 'react'
import '../components/styles/advantages.css'
function Advantages() {
    return (
        <div className="container">
            <div className='advantage-hero row'>
                <div className='col-xs-12'>
                    <h2 className='ad-head'>Advantages of Our Active Challenge-Based Method</h2>
                </div>
                <div className='col-xs-12'>
                    <ul className="ad-ul">
                        <li>
                            <strong>Enhanced Security:</strong> By requiring
                            real-time facial gestures, our method effectively
                            prevents spoofing attempts using photos, videos, or
                            masks.
                        </li>
                        <li>
                            <strong>Dynamic and Unpredictable:</strong> The
                            random sequence of gestures and real-time response
                            requirements make it nearly impossible for attackers
                            to replicate or predict the process.
                        </li>
                        <li>
                            <strong>Improved Accuracy:</strong> Unlike passive
                            checks, which rely on sending a face image to a
                            server for analysis, our active challenge-based
                            approach minimizes false positives and ensures a
                            high level of accuracy.
                        </li>
                        <li>
                            <strong>Immediate Feedback:</strong> Users receive
                            instant feedback on their actions, making the
                            process quick and user-friendly.
                        </li>
                        <li>
                            <strong>Privacy Protection:</strong> Passive
                            liveness checks, which involve sending face images
                            to a server, can expose personally identifiable
                            information (PII) to potential security breaches or
                            misuse. Our method processes data locally,
                            significantly reducing the risk of PII exposure and
                            ensuring your data remains safe.
                        </li>
                    </ul>

                    <p className="ad-p"
                    >
                        Our patented liveness check technology is designed to
                        provide a secure, efficient, and user-friendly
                        experience. By leveraging this advanced verification
                        process, we ensure that your identity remains protected
                        against fraud and unauthorized access.
                    </p>
                </div>


            </div>
        </div>
    )
}

export default Advantages