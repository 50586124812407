export const Verification = (props) => {
    return (
        <div id="Verification" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Face recognition & verification</h2>
                    <p>
                        Face recognition and face verification as a service play
                        a crucial role in both centralized and decentralized
                        identity solutions by providing a reliable and efficient
                        means of authenticating users. Our powerful AI algorithm
                        enhances these services, making them highly accurate,
                        fast, and reliable as they analyze facial features for
                        quick identity verification. In centralized systems,
                        they enhance traditional KYC processes by automating
                        identity checks and reducing manual verification
                        efforts, thus improving compliance and security. In
                        decentralized identity frameworks, face recognition
                        empowers users to authenticate themselves while
                        maintaining control over their personal data. By
                        seamlessly integrating biometric authentication into
                        identity solutions, organizations can offer a
                        user-friendly experience while bolstering security
                        measures against identity theft and fraud, fostering
                        greater trust in digital interactions.
                    </p>
                </div>
            </div>
        </div>
    );
};
