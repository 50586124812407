/* eslint-disable no-unused-vars */
import React from 'react';
import '../components/styles/video.css'; // Import CSS for styling

import backgroundVideo from '../video/background3.mp4'; // Make sure to import your video

export const Header = (props) => {
    return (
        <header id="header">
            <div className="video-container">
                <video
                    src={backgroundVideo}
                    type="video/mp4"
                    autoPlay
                    muted
                    loop
                    id="background-video"
                ></video>
            </div>
        </header>
    );
};
