export const Decentralized = (props) => {
    return (
        <div id="Decentralized" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Decentralized identity solution</h2>
                    <p>
                        A decentralized identity authentication solution
                        enhances website security by leveraging blockchain
                        technology to store and manage user identities in a
                        tamper-proof manner. This innovative approach allows
                        users to control their personal information, ensuring
                        privacy while simplifying the verification process. By
                        incorporating face recognition biometrics, the solution
                        adds an additional layer of security, enabling quick and
                        reliable user authentication. Users can seamlessly log
                        in by simply scanning their faces, which are matched
                        against stored biometric templates, significantly
                        reducing the risk of identity theft and fraud. This
                        combination of decentralization and biometric technology
                        not only streamlines user experiences but also fosters
                        greater trust and security in digital interactions.
                    </p>
                </div>

                {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
            </div>
        </div>
    );
};
