import React, { useState, useEffect } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { Features } from './components/features';
import { About } from './components/about';
import { Decentralized } from './components/Decentralized';
import { Ekyc } from './components/Ekyc';
import { Verification } from './components/Verification';
import {
    LivenessDetection,
    livenessDetection,
} from './components/LivenessDetection';
import { Gallery } from './components/gallery';
import { Testimonials } from './components/testimonials';
import { Team } from './components/Team';
import { Contact } from './components/contact';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import './App.css';
import HeaderContent from './components/HeaderContent';
import Footer from './components/Footer';
import Faceliveness from './components/Faceliveness';
import { Exclusives } from './components/Exclusive';
import NewExclusive from './components/NewExclusive';
import Advantages from './components/Advantages';
import Contact2 from './components/Contact2';
import Partner from './components/Partner'

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const App = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <div>
            <Navigation />
            <Header />
            <HeaderContent data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <Decentralized data={landingPageData.Services} />
            <Ekyc />
            <Verification />
            <LivenessDetection />
            {/* <Faceliveness /> */}
            <About data={landingPageData.About} />
            <NewExclusive/>
            <Advantages/>
            <Gallery data={landingPageData.Gallery} />
            <Exclusives />
            <Testimonials data={landingPageData.Testimonials} />
            <Team data={landingPageData.Team} />
            <Contact2 />
            <Partner/>
            <Contact data={landingPageData.Contact} />
            <Footer />
        </div>
    );
};

export default App;
