import React, { useState } from 'react';
import './styles/styles.css';
import Logo from '../assets/Images/Logofile.png'; // Adjust the path if needed

export const Navigation = (props) => {
    const [dropdowns, setDropdowns] = useState({
        products: false,
        about: false,
        industries: false,
        useCase: false,
        customer: false,
        plans: false,
    });

    const toggleDropdown = (dropdown) => {
        setDropdowns({
            ...dropdowns,
            [dropdown]: !dropdowns[dropdown],
        });
    };

    return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header navbar-custom">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                    >
                        {' '}
                        <span className="sr-only">Toggle navigation</span>{' '}
                        <span className="icon-bar"></span>{' '}
                        <span className="icon-bar"></span>{' '}
                        <span className="icon-bar"></span>{' '}
                    </button>
                    <a className="navbar-brand page-scroll" href="#page-top">
                        <img
                            src={Logo}
                            className="logonav"
                            width="150px"
                            alt="logo"
                        />
                    </a>{' '}
                </div>

                <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li className="dropdown">
                            <a
                                href="#features"
                                className="page-scroll"
                                onClick={() => toggleDropdown('products')}
                            >
                                Products{' '}
                                <span className="dropdown-arrow">▼</span>
                            </a>
                            {dropdowns.products && (
                                <div className="dropdown-content">
                                    <a href="#Decentralized">
                                        <i className="icon icon-product1"></i>{' '}
                                        Decentralized Identity Solution
                                    </a>
                                    <a href="#ekyc">
                                        <i className="icon icon-product2"></i>{' '}
                                        Centralized Identity & KYC
                                    </a>
                                    <a href="#Verification">
                                        <i className="icon icon-product3"></i>{' '}
                                        Face Recognition and Verification
                                    </a>
                                    <a href="#Liveness">
                                        <i className="icon icon-product3"></i>{' '}
                                        Face Liveness Detection
                                    </a>
                                </div>
                            )}
                        </li>
                        <li className="dropdown">
                            <a
                                href="#Liveness"
                                className="page-scroll"
                                onClick={() => toggleDropdown('customer')}
                            >
                                Face Liveness{' '}
                            </a>
                        </li>
                        <li className="dropdown">
                            <a
                                href="#about"
                                className="page-scroll"
                                // onClick={() => toggleDropdown('about')}
                            >
                                About <span className="dropdown-arrow"></span>
                            </a>
                            {/* {dropdowns.about && (
                                // <div className="dropdown-content">
                                //     {/* <a href="#about1">
                                //         <i className="icon icon-about1"></i>{' '}
                                //         About Section
                                //     </a> */}
                            {/* // </div> */}
                            {/* )} */}
                        </li>

                        <li className="dropdown">
                            <a
                                href="#portfolio"
                                className="page-scroll"
                                onClick={() => toggleDropdown('useCase')}
                            >
                                Videos <span className="dropdown-arrow">▼</span>
                            </a>
                            {dropdowns.useCase && (
                                <div className="dropdown-content">
                                    <a href="#usecase1">
                                        <i className="icon icon-usecase1"></i>{' '}
                                        Self Sovereign Identity (SSI)
                                    </a>
                                    <a href="#usecase2">
                                        <i className="icon icon-usecase2"></i>{' '}
                                        Biometrik Wallet App
                                    </a>
                                    {/* <a href="#usecase3">
                                        <i className="icon icon-usecase3"></i>{' '}
                                        Video 3
                                    </a> */}
                                </div>
                            )}
                        </li>
                        <li className="dropdown">
                            <a
                                href="#portfolio"
                                className="page-scroll"
                                onClick={() => toggleDropdown('useCase')}
                            >
                                Exclusives{' '}
                                {/* <span className="dropdown-arrow">▼</span> */}
                            </a>
                            {dropdowns.useCase && (
                                <div className="dropdown-content">
                                    {/* <a href="#usecase1">
                                        <i className="icon icon-usecase1"></i>{' '}
                                        Self Sovereign Identity (SSI)
                                    </a>
                                    <a href="#usecase2">
                                        <i className="icon icon-usecase2"></i>{' '}
                                        Biometrik Wallet App
                                    </a> */}
                                    {/* <a href="#usecase3">
                                        <i className="icon icon-usecase3"></i>{' '}
                                        Video 3
                                    </a> */}
                                </div>
                            )}
                        </li>
                        <li className="dropdown">
                            <a
                                href="#testimonials"
                                className="page-scroll"
                                onClick={() => toggleDropdown('industries')}
                            >
                                USE CASES{' '}
                                <span className="dropdown-arrow">▼</span>
                            </a>
                            {dropdowns.industries && (
                                <div className="dropdown-content">
                                    <a href="#industry1">
                                        <i className="icon icon-industry1"></i>{' '}
                                        Identity verification use cases
                                    </a>
                                    {/* <a href="#industry2">
                                        <i className="icon icon-industry2"></i>{' '}
                                        Use Case 2
                                    </a>
                                    <a href="#industry3">
                                        <i className="icon icon-industry3"></i>{' '}
                                        Use Case 3
                                    </a> */}
                                </div>
                            )}
                        </li>

                        <li className="dropdown">
                            <a
                                href="#team"
                                className="page-scroll"
                                onClick={() => toggleDropdown('plans')}
                            >
                                Team <span className="dropdown-arrow">▼</span>
                            </a>
                            {dropdowns.plans && (
                                <div className="dropdown-content">
                                    <a href="#plan1">
                                        <i className="icon icon-plan1"></i>
                                        Leadership
                                    </a>
                                    {/* <a href="#plan2">
                                        <i className="icon icon-plan2"></i> Plan
                                        2
                                    </a>
                                    <a href="#plan3">
                                        <i className="icon icon-plan3"></i> Plan
                                        3
                                    </a> */}
                                </div>
                            )}
                        </li>
                        <li>
                            <a href="#contact" className="page-scroll">
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
